import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <a id="presitge-classes"></a><h2>Prestige Classes</h2>
    <a id="mystic-theurge"></a><h3>MYSTIC THEURGE</h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td className="line"> </td>
        </tr>
      </tbody>
    </table>
    <p className="initial"><a href="http://www.wizards.com/dnd/images/dmg35_gallery/DMG35_PG192_WEB.jpg"><img className="art-wide" src="pict.jpg" title="" alt="pict" style={{
          "border": "0px solid",
          "width": "73px",
          "height": "75px"
        }} /></a><b>Hit Die</b>:
d4.</p>
    <a id="mystic-theurge-requirements"></a><h6>Requirements</h6>
    <p className="initial">To qualify to become a mystic theurge, a
character must fulfill all the following criteria.</p>
    <p><b>Skills</b>: Knowledge (arcana) 6 ranks, Knowledge
(religion) 6 ranks.</p>
    <p><b>Spells</b>: Able to cast 2nd-level divine spells and
2nd-level arcane spells.</p>
    <h6>Class Skills</h6>
    <p className="initial">The mystic theurge&#8217;s class skills (and the key
ability for each skill) are <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#concentration">Concentration</a>
(Con), <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#craft">Craft</a>
(Int), <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#decipher-script">Decipher Script</a> (Int), <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#knowledge">Knowledge</a>
(arcana/religion) (Int), <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#profession">Profession</a> (Wis), <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#sense-motive">Sense
Motive</a> (Wis), and <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#spellcraft">Spellcraft</a> (Int). </p>
    <p><b>Skill Points at Each Level</b>: 2 + Int modifier.</p>
    <a id="table-the-mystic-theurge"></a><p><b>Table: The Mystic Theurge</b></p>
    <table style={{
      "width": "100%"
    }} border="0" cellPadding="1" cellSpacing="0">
      <tbody>
        <tr valign="bottom">
          <th style={{
            "width": "5%"
          }}>Level</th>
          <th style={{
            "width": "10%"
          }}>Base
Attack Bonus</th>
          <th style={{
            "width": "5%"
          }}>Fort
Save</th>
          <th style={{
            "width": "5%"
          }}>Ref
Save</th>
          <th style={{
            "width": "5%"
          }}>Will
Save</th>
          <th style={{
            "width": "60%"
          }}>Spells per Day</th>
        </tr>
        <tr className="odd-row">
          <td>1st</td>
          <td>+0</td>
          <td>+0</td>
          <td>+0</td>
          <td>+2</td>
          <td>+1 level of existing arcane spellcasting class/+1 level
of existing divine spellcasting class</td>
        </tr>
        <tr>
          <td>2nd</td>
          <td>+1</td>
          <td>+0</td>
          <td>+0</td>
          <td>+3</td>
          <td>+1 level of existing arcane spellcasting class/+1 level
of existing divine spellcasting class</td>
        </tr>
        <tr className="odd-row">
          <td>3rd</td>
          <td>+1</td>
          <td>+1</td>
          <td>+1</td>
          <td>+3</td>
          <td>+1 level of existing arcane spellcasting class/+1 level
of existing divine spellcasting class</td>
        </tr>
        <tr>
          <td>4th</td>
          <td>+2</td>
          <td>+1</td>
          <td>+1</td>
          <td>+4</td>
          <td>+1 level of existing arcane spellcasting class/+1 level
of existing divine spellcasting class</td>
        </tr>
        <tr className="odd-row">
          <td>5th</td>
          <td>+2</td>
          <td>+1</td>
          <td>+1</td>
          <td>+4</td>
          <td>+1 level of existing arcane spellcasting class/+1 level
of existing divine spellcasting class</td>
        </tr>
        <tr>
          <td>6th</td>
          <td>+3</td>
          <td>+2</td>
          <td>+2</td>
          <td>+5</td>
          <td>+1 level of existing arcane spellcasting class/+1 level
of existing divine spellcasting class</td>
        </tr>
        <tr className="odd-row">
          <td>7th</td>
          <td>+3</td>
          <td>+2</td>
          <td>+2</td>
          <td>+5</td>
          <td>+1 level of existing arcane spellcasting class/+1 level
of existing divine spellcasting class</td>
        </tr>
        <tr>
          <td>8th</td>
          <td>+4</td>
          <td>+2</td>
          <td>+2</td>
          <td>+6</td>
          <td>+1 level of existing arcane spellcasting class/+1 level
of existing divine spellcasting class</td>
        </tr>
        <tr className="odd-row">
          <td>9th</td>
          <td>+4</td>
          <td>+3</td>
          <td>+3</td>
          <td>+6</td>
          <td>+1 level of existing arcane spellcasting class/+1 level
of existing divine spellcasting class</td>
        </tr>
        <tr>
          <td className="last-row">10th</td>
          <td className="last-row">+5</td>
          <td className="last-row">+3</td>
          <td className="last-row">+3</td>
          <td className="last-row">+7</td>
          <td className="last-row">+1 level of existing arcane
spellcasting class/+1 level of existing divine spellcasting class</td>
        </tr>
      </tbody>
    </table>
    <a id="mystic-theurge-class-features"></a><h6>Class Features</h6>
    <p className="initial">All of the following are features of the
mystic theurge prestige class.</p>
    <p><b>Weapon and Armor Proficiency</b>: Mystic theurges gain no
proficiency with any weapon or armor.</p>
    <p><b>Spells per Day</b>: When a new mystic theurge level is
gained, the character gains new spells per day as if he had also gained
a level in any one arcane spellcasting class he belonged to before he
added the prestige class and any one divine spellcasting class he
belonged to previously. He does not, however, gain any other benefit a
character of that class would have gained. This essentially means that
he adds the level of mystic theurge to the level of whatever other
arcane spellcasting class and divine spellcasting class the character
has, then determines spells per day and caster level accordingly.</p>
    <p>If a character had more than one arcane spellcasting class or
more than one divine spellcasting class before he became a mystic
theurge, he must decide to which class he adds each level of mystic
theurge for the purpose of determining spells per day.</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      